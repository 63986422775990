/**
 * Dev environment
 *
 * Put what you like in it, but DON'T use it to build prod !
 */
export const environment = {
  production: false,
  title: 'Weezchat, chat ilimitado',
  api: 'https://chat-api-new-pp.contactdve.com/api',
  chat: 'https://pp-chat-liveengine.contactdve.com/',
  extCode: 'ES',
  key: 1,
  newsSectionUrl: 'https://m.weezchat.es/news',
  footerText: null,
  footerLinks: [],
  LP: null,
  redirectIfNoIframe: null,
  useBothSignUpMethod: false,
  avatarSize: 1200,
  gtm: 'GTM-K2XWJZP',
  showAllCities: false,
  RTL: false,
  completeProfileInputs: ['email', 'phoneNumber'],
  unsubscribeLink: false,
freeSubscription : false,
  home: {
    app: {
      available: false,
      linkGoogle: '',
      linkApple: '',
    },
    facebook: {
      available: false,
      link: ''
    },
    twitter: {
      available: false,
      link: ''
    },
    instagram: {
      available: false,
      link: ''
    }
  },
  RGPD : {
    cookies: {
      available: true,
      link: 'https://www.dgp-legal.com/en/cookies-information/'
    }
  }
};
